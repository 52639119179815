<template>
<div>
  <div class="container">
    <router-link tag="header" to="/">
      <img src="~@/assets/img/logo-w-b.png" class="bg-big">
      <img src="~@/assets/img/logo-w-s.png" class="bg-small">
      <h1>魯凱族藥草園知識平台</h1>
    </router-link>

    <article class="pb-5" v-if="!loading">
      <h2 class="title">查詢結果</h2>
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="form-area">
            <h3 data-toggle="collapse" href="#collapSearch" aria-expanded="true"><span class="switch">藥草查詢</span></h3>
            <div class="collapse show" id="collapSearch">
              <div class="row mb-2">
                <div class="col-12 col-lg-4 my-1">
                  <input type="text" class="form-control" v-model="search.keyword" placeholder="請輸入關鍵字" @keyup.enter="btnSearch">
                </div>
                <div class="col-12 col-lg-4 my-1">
                  <select
                    @change="btnSearch"
                    class="form-control"
                    v-model="search.type"
                  >
                    <option value="">不限欄位</option>
                    <option value="name">植物名稱</option>
                    <option value="scientific_name">學名</option>
                    <option value="another_name">別名</option>
                    <option value="drekay">魯凱族語</option>
                  </select>
                </div>
                <div class="col-12 col-lg-4 my-1">
                  <button class="btn btn-grassgreen w-100" @click="btnSearch">查詢</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-area">
            <h3 data-toggle="collapse" href="#collapAdvance" aria-expanded="true"><span class="switch">藥草類別</span></h3>
            <div class="collapse show" id="collapAdvance">
              <div class="row mb-2">
                <div class="col-12 my-1">
                  <Treeselect
                    :multiple="true"
                    :options="getSelectOptions"
                    @input="btnSearch"
                    placeholder="不限類別、不限科名"
                    v-model="search.options"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column justify-content-cente align-items-center" v-if="!showDatas.length">
        <img src="~@/assets/img/icon-warning.svg" class="w-25">
        <h3 class="text-secondary">查無資料</h3>
        <p>您搜尋的關鍵字查無資料，請重新查詢</p>
      </div>
      <ul class="table -style" v-else>
        <li class="thead">
          <ol class="tr">
            <li>植物名稱</li>
            <li>科名</li>
            <li>學名</li>
            <li>別名</li>
            <li>魯凱族語</li>
            <li>資訊</li>
          </ol>
        </li>
        <li class="tbody">
          <ol class="tr" v-for="data of showDatas" :key="data.id">
            <li data-title="植物名稱">{{ data.name }}</li>
            <li data-title="科名">{{ data.family_cn }}</li>
            <li data-title="學名" class="ta-l">{{ data.scientific_name }}</li>
            <li data-title="別名" class="ta-l">{{ data.another_name }}</li>
            <li data-title="魯凱族語" class="ta-l">{{ data.drekay }}</li>
            <li data-title="資訊">
              <router-link tag="button" class="btn_detail" :to="`/detail/${data.id}`">詳細資訊</router-link>
            </li>
          </ol>
        </li>
      </ul>
      <div class="d-flex justify-content-center pt-5" v-if="result.length !== showDatas.length">
        <button class="btn btn-outline-grassgreen w-50" @click="moreCount++">更多</button>
      </div>
    </article>
  </div>
  <div class="bg-main"></div>
  <div class="bg-mask"></div>
  <div class="bg-photo -result"></div>
</div>
</template>

<script>
import _ from 'lodash'
import '@/assets/css/vue-treeselect.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Fuse from 'fuse.js'
import Treeselect from '@riophae/vue-treeselect'

export default {
  name: 'RukaiHerbalSearch',
  components: { Treeselect },
  watch: {
    '$route.query': {
      handler: function () {
        this.search = {
          keyword: this.$route.query?.keyword ?? '',
          type: this.$route.query?.type ?? '',
          options: _.compact(_.castArray(this.$route.query?.options)),
        }
        this.result = this.getResult()
      },
      deep: true,
      immediate: true,
    },
  },
  data () {
    return {
      loading: true,
      moreCount: 1,
      result: [],
      search: {
        keyword: this.$route.query?.keyword ?? '',
        type: this.$route.query?.type ?? '',
        options: _.compact(_.castArray(this.$route.query?.options)),
      },
    }
  },
  computed: {
    getSelectOptions () {
      return _.map(_.groupBy(this.$store.state.herbalDatas, 'category_cn'), (datas, category) => {
        const families = _.uniq(_.map(datas, 'family_cn'))
        return {
          id: `category-${category}`,
          label: category,
          children: _.map(families, family => ({
            id: `family-${family}`,
            label: family,
          })),
        }
      })
    },
    showDatas () {
      if (!_.compact(_.values(this.$route.query)).length) this.$set(this, 'result', this.$store.state.herbalDatas)
      return _.take(this.result, 6 * this.moreCount)
    },
  },
  async mounted () {
    await this.init()
    this.result = this.getResult()
    this.loading = false
  },
  methods: {
    async init () {
      const { state, dispatch } = this.$store
      try {
        if (state.herbalDatas.length) return
        window.showLoading('載入中', 'Loading...')
        await dispatch('getHerbals')
        this.$swal.close()
      } catch (err) {
        console.error(err)
        await this.$swal.fire({
          icon: 'error',
          text: err.message,
          title: '發生錯誤',
        })
        this.$router.push({ path: '/' })
      }
    },
    btnSearch () {
      this.moreCount = 1
      this.$router.push({
        path: this.$router.path,
        query: this.search,
      })
    },
    filterBySelect () {
      const herbalDatas = this.$store.state.herbalDatas
      const datas = {
        category: _.groupBy(herbalDatas, 'category_cn'),
        family: _.groupBy(herbalDatas, 'family_cn'),
      }
      return _.transform(this.search.options, (result, option) => {
        const [type, id] = option.split('-')
        result.push(...datas[type][id])
      }, [])
    },
    getResult () {
      let datas = this.$store.state.herbalDatas
      if (!datas.length) return []
      const search = this.search
      if (search.options?.length) datas = this.filterBySelect()
      if (!search.keyword) return datas

      const options = {
        includeScore: true,
        keys: search.type ? [search.type] : [
          'another_name',
          'scientific_name',
          'drekay',
          'name',
        ],
      }
      const fuse = new Fuse(datas, options)
      return _.map(fuse.search(search.keyword), 'item')
    },
  },
}
</script>
